const API_BASE_URL = "https://api.videosdk.live";
const VIDEOSDK_TOKEN = process.env.REACT_APP_VIDEOSDK_TOKEN;
const API_AUTH_URL = process.env.REACT_APP_AUTH_URL;

export const getToken = async (room, userCode, therapistCode, source) => {

  if (VIDEOSDK_TOKEN) {
    return VIDEOSDK_TOKEN;
  } else if (API_AUTH_URL) {
    const meetingBaseUrl = source === "next" ? "https://www.session-admin.felicity.care/api": "https://api.felicity.care/guest"
    const res = await fetch(`${meetingBaseUrl}/meeting/get-token?room=${room}&userCode=${userCode}&therapistCode=${therapistCode}`, {
      method: "GET",
    });
    const { token, role } = await res.json();
    console.log(token, role)
    return { token, role };
  } else {
    console.error("Error: ", Error("Please add a token or Auth Server URL"));
  }
};

export const createMeeting = async ({ token }) => {
  const url = `${API_BASE_URL}/v2/rooms`;
  const options = {
    method: "POST",
    headers: { Authorization: token, "Content-Type": "application/json" },
  };

  const response = await fetch(url, options)
  const data = await response.json()

  if (data.roomId) {
    return { meetingId: data.roomId, err: null }
  } else {
    return { meetingId: null, err: data.error }
  }

};

export const validateMeeting = async ({ roomId, token }) => {
  const url = `${API_BASE_URL}/v2/rooms/validate/${roomId}`;

  const options = {
    method: "GET",
    headers: { Authorization: token, "Content-Type": "application/json" },
  };

  const response = await fetch(url, options)

  if (response.status === 400) {
    const data = await response.text()
    return { meetingId: null, err: data }
  }

  const data = await response.json()

  if (data.roomId) {
    return { meetingId: data.roomId, customRoomId: data.customRoomId ? data.customRoomId : '', err: null }
  } else {
    return { meetingId: null, err: data.error }
  }

};

export const markStarted = async ({ roomName, role }) => {
  const res = await fetch(`https://api.felicity.care/guest/meeting/mark-as-started?room=${roomName}&role=${role}`, {
    method: "GET",
  });
};